
















import { Component, Vue } from 'vue-property-decorator'
import ImportAndUpload from '../components/Youtube/ImportAndUpload.vue'

@Component({
  components: { ImportAndUpload },
})
export default class ViewImportAndUpload extends Vue {}
