var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "flex flex-wrap" }, [
      _c("div", { staticClass: "custom-limiter scrollbar" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass:
              "w-100 bg-adori-light-gray br2 max-height flex justify-center pa4",
          },
          [_c("ImportAndUpload", { attrs: { "hide-upload-audio": "" } })],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w-100 flex justify-center mt3 mb3" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/adori-new-logo.png"),
          alt: "",
          height: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }